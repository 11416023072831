<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12 col-md-6 border-md-right">
                    <div class="row">
                        <div class="col col-12">
                            <vias-input
                                label="Placa"
                                v-model="form.placa"
                                mask="AAA#X##"
                                @input="atualizarTextoDaPlaca()"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <HorariosSelect
                                v-model="horarioSelecionado"
                                @change="atualizarHorarioDaBusca($event)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <ViasInputDateAndTimeRange
                                label="Selecione o período"
                                v-model="form.data"
                                @change="desselecionarHorario()"
                            />
                        </div>
                    </div>
                </div>
                <div class="col col-12 col-md-6">
                    <div class="row">
                        <div class="col col-12">
                            <vias-select
                                label="Buscar da rota..."
                                v-model="form.rota_id"
                                :options="rotas"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <vias-select
                                label="Incluir na busca..."
                                v-model="form.incluir"
                                :options="inclusao"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 pl-2">
                    <vias-button variant="primary">
                        Buscar relatório
                    </vias-button>
                </div>
            </div>
        </form>
    </vias-card>

    <vias-card v-if="historico" class="mt-4">
        <h3 class="mb-4">
            Como é calculado a velocidade média?
            <ViasExplicacaoVelocidadeMedia/>
        </h3>

        <h3 class="mb-4">
            Como é calculado a velocidade considerada?
            <ViasExplicacaoVelocidadeConsiderada/>
        </h3>

        <div class="overflow-x-auto">
            <table class="table">
                <thead>
                    <tr>
                        <th>Placa</th>
                        <th>Data do Primeiro Fluxo</th>
                        <th>Velocidade no Primeiro Fluxo</th>
                        <th>Data do Segundo Fluxo</th>
                        <th>Velocidade no Segundo Fluxo</th>
                        <th>Tempo Entre os Equipamentos</th>
                        <th>Velocidade Média Medida</th>
                        <th>Velocidade Média Considerada</th>
                        <th>Infração?</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="linha in historico.dados" :key="linha.id">
                        <td>{{ linha.placa }}</td>
                        <td>
                            {{
                                moment(linha.primeira_passagem_data).format(
                                    "DD/MM/YYYY HH:mm:ss",
                                )
                            }}
                        </td>
                        <td>
                            {{ linha.primeira_passagem_velocidade || "--" }}
                            km/h
                        </td>
                        <td>
                            {{
                                moment(linha.segunda_passagem_data).format(
                                    "DD/MM/YYYY HH:mm:ss",
                                )
                            }}
                        </td>
                        <td>
                            {{ linha.segunda_passagem_velocidade || "--" }} km/h
                        </td>
                        <td>{{ linha.tempo_de_travessia || "--" }} segundos</td>
                        <td>
                            {{
                                linha.velocidade_medida
                                    ? linha.velocidade_medida.toFixed(0)
                                    : "--"
                            }}
                            km/h
                        </td>
                        <td>
                            {{
                                linha.velocidade_considerada
                                    ? linha.velocidade_considerada.toFixed(0)
                                    : "--"
                            }}
                            km/h
                        </td>
                        <td>{{ linha.infracao ? "Infração" : "Regular" }}</td>
                        <td>
                            <a
                                v-if="linha.infracao"
                                :href="
                                    '/velocidade-media/relatorios/infracao-especifica/' +
                                    linha.id
                                "
                                target="_blank"
                                class="btn btn-primary btn-sm"
                            >
                                <font-awesome-icon
                                    icon="fas fa-info-circle"
                                ></font-awesome-icon>
                            </a>
                        </td>
                    </tr>
                    <tr v-if="!hasDadosParaApresentar">
                        <td colspan="10">
                            <h3 class="text-center">
                                Não encontramos infrações ou fluxo com as informações passadas.
                            </h3>
                        </td>
                    </tr>
                </tbody>
            </table>
            <vias-pagination
                class="mt-4 mb-4"
                :quantidade="historico.total_de_paginas"
                :atual="historico.pagina"
                @mudarDePagina="mudarDePagina($event)"
            ></vias-pagination>
        </div>
    </vias-card>
</template>

<script>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasInput from "@/components/shared/ViasInput.vue";
import ViasSelect from "@/components/shared/ViasSelect.vue";
import ViasPagination from "@/components/shared/ViasPagination.vue";
import ViasInputDateAndTimeRange from "@/components/shared/ViasInputDateAndTimeRange.vue";
import HorariosSelect from "@/domains/Horarios/components/HorariosSelect.vue";
import ViasExplicacaoVelocidadeMedia from "@/components/shared/explicacoes/ViasExplicacaoVelocidadeMedia.vue";
import ViasExplicacaoVelocidadeConsiderada from "@/components/shared/explicacoes/ViasExplicacaoVelocidadeConsiderada.vue";
import moment from "moment";
import {
    buscarRotas,
    buscarHistoricoDeOcorrenciasDeVelocidadeMedia,
} from "@/services/average-speed.service";
import { close, open } from "@/services/notification.service";
import datetimeService from "@/core/composables/DatetimeService";

export default {
    components: {
        ViasCard,
        ViasButton,
        ViasInputDateAndTimeRange,
        ViasInput,
        ViasSelect,
        ViasPagination,
        HorariosSelect,
        ViasExplicacaoVelocidadeMedia,
        ViasExplicacaoVelocidadeConsiderada,
    },
    data: () => ({
        form: {
            data: [
                moment().format("YYYY-MM-DDT00:00:00"),
                moment().format("YYYY-MM-DDTHH:mm:ss"),
            ],
            rota_id: "",
            placa: "",
            incluir: "INFRACAO",
            page: 0,
        },
        horarioSelecionado: 0,
        rotas: [
            {
                value: "",
                text: "Todas as rotas",
            },
        ],
        inclusao: [
            {
                text: "Infrações e regulares",
                value: "TODAS",
            },
            {
                text: "Apenas infrações",
                value: "INFRACAO",
            },
            {
                text: "Apenas regulares",
                value: "REGULAR",
            },
        ],
        historico: null,
        moment,
    }),
    computed: {
        hasDadosParaApresentar() {
            if (this.historico) {
                return this.historico.dados.length > 0;
            }
            return null;
        },
    },
    methods: {
        buscarRotas() {
            buscarRotas()
                .then((res) => res.data)
                .then((rotas) => {
                    this.rotas = this.rotas.concat(
                        rotas.map((rota) => ({
                            text: rota.nome,
                            value: rota.id,
                        })),
                    );
                });
        },
        atualizarHorarioDaBusca(horario) {
            this.form.data[0] = datetimeService.setTimeOnDatetime(this.form.data[0], horario[0]);
            this.form.data[1] = datetimeService.setTimeOnDatetime(this.form.data[1], horario[1]);
        },
        desselecionarHorario() {
            this.horarioSelecionado = 0;
        },
        buscarRelatorio(paginaInicial = true) {
            open("Buscando histórico, por favor, aguarde um momento...");

            if (paginaInicial) {
                this.form.page = 0;
            }

            buscarHistoricoDeOcorrenciasDeVelocidadeMedia(this.form)
                .then((res) => res.data)
                .then((historico) => {
                    this.historico = {
                        ...historico,
                        dados: historico.dados.map((dado) => ({
                            ...dado,
                            tempo_de_travessia: this.$numero.formatar(dado.tempo_de_travessia)
                        }))
                    };
                })
                .then(() => {
                    close("Procedimento finalizado!");
                });
        },
        atualizarTextoDaPlaca() {
            this.form.placa = this.form.placa.toUpperCase();
        },
        mudarDePagina(pagina) {
            this.form.page = pagina;
            this.buscarRelatorio(false);
        },
    },
    created() {
        this.buscarRotas();
    },
};
</script>
