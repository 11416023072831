const MarkerPlugin = {
    install(app) {
        app.config.globalProperties.$marker = {
            ponteiros: {
                FL: {
                    online: require("@/assets/ponteiros/FL/online.png"),
                    offline: require("@/assets/ponteiros/FL/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/FL/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/FL/sem-deteccao-recente.png"),
                },
                FM: {
                    online: require("@/assets/ponteiros/FM/online.png"),
                    offline: require("@/assets/ponteiros/FM/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/FM/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/FM/sem-deteccao-recente.png"),
                },
                FS: {
                    online: require("@/assets/ponteiros/FS/online.png"),
                    offline: require("@/assets/ponteiros/FS/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/FS/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/FS/sem-deteccao-recente.png"),
                },
                FV: {
                    online: require("@/assets/ponteiros/FV/online.png"),
                    offline: require("@/assets/ponteiros/FV/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/FV/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/FV/sem-deteccao-recente.png"),
                },
                LM: {
                    online: require("@/assets/ponteiros/LM/online.png"),
                    offline: require("@/assets/ponteiros/LM/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/LM/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/LM/sem-deteccao-recente.png"),
                },
                DF: {
                    online: require("@/assets/ponteiros/DF/online.png"),
                    offline: require("@/assets/ponteiros/DF/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/DF/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/DF/sem-deteccao-recente.png"),
                },
                ES: {
                    online: require("@/assets/ponteiros/ES/online.png"),
                    offline: require("@/assets/ponteiros/ES/offline.png"),
                    nunca_usado: require("@/assets/ponteiros/ES/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros/ES/sem-deteccao-recente.png"),
                },
            },
            ponteiros_selecionados: {
                FL: {
                    online: require("@/assets/ponteiros-selecionados/FL/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/FL/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/FL/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/FL/sem-deteccao-recente.png"),
                },
                FM: {
                    online: require("@/assets/ponteiros-selecionados/FM/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/FM/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/FM/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/FM/sem-deteccao-recente.png"),
                },
                FS: {
                    online: require("@/assets/ponteiros-selecionados/FS/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/FS/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/FS/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/FS/sem-deteccao-recente.png"),
                },
                FV: {
                    online: require("@/assets/ponteiros-selecionados/FV/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/FV/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/FV/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/FV/sem-deteccao-recente.png"),
                },
                LM: {
                    online: require("@/assets/ponteiros-selecionados/LM/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/LM/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/LM/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/LM/sem-deteccao-recente.png"),
                },
                DF: {
                    online: require("@/assets/ponteiros-selecionados/DF/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/DF/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/DF/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/DF/sem-deteccao-recente.png"),
                },
                ES: {
                    online: require("@/assets/ponteiros-selecionados/ES/online.png"),
                    offline: require("@/assets/ponteiros-selecionados/ES/offline.png"),
                    nunca_usado: require("@/assets/ponteiros-selecionados/ES/nunca-usado.png"),
                    sem_deteccao_recente: require("@/assets/ponteiros-selecionados/ES/sem-deteccao-recente.png"),
                },
            },
        };
    },
};

export default MarkerPlugin;
