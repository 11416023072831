<template>
    <a v-if="isExterno" class="link" :href="link.uri" target="_blank">
        <i class="icone" :class="hasIcon"></i>
        <h5>{{ link.texto }}</h5>
    </a>
    <router-link
        v-else-if="hasLinks && isSuperUser"
        :to="link.uri"
        custom
        v-slot="{ href }"
    >
        <div class="link-dropdown" :class="theme">
            <a
                :href="href"
                :class="[theme, routeHasThisLink]"
                class="link"
                @click.stop.prevent="preventRedirect($event, href)"
                :data-cy="link.data_cy"
                ref="link"
            >
                <i class="icone" :class="hasIcon"></i>
                <h5>{{ link.texto }}</h5>
                <i class="dropdown-indicator fas fa-angle-left"></i>
            </a>

            <div class="dropdown" :class="theme">
                <vias-aside-link
                    v-for="child in link.children"
                    :key="child"
                    :link="child"
                ></vias-aside-link>
            </div>
        </div>
    </router-link>

    <router-link
        v-else-if="link.texto == 'Sair'"
        :to="link.uri"
        :class="theme"
        class="link"
        custom
    >
        <a
            data-cy="logout"
            class="dark-theme link"
            href="#"
            @click="emitClick($event, link)"
        >
            <i class="icone" :class="hasIcon"></i>
            <h5>{{ link.texto }}</h5>
        </a>
    </router-link>

    <router-link
        v-else-if="isSuperUser"
        :to="link.uri"
        :class="theme"
        :data-cy="link.data_cy"
        class="link"
    >
        <i class="icone" :class="hasIcon"></i>
        <h5>{{ link.texto }}</h5>
    </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "vias-aside-link",
    props: ["link"],
    emits: ["click"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
            usuario: (state) => state.usuario,
        }),
        hasLinks() {
            return this.link.children && this.link.children.length > 0;
        },
        routeHasThisLink() {
            return this.$route.path.startsWith(this.link.uri)
                ? "active open"
                : "";
        },
        isSuperUser() {
            return this.link.super_usuario
                ? this.usuario && this.usuario.context.super_usuario
                : true;
        },
        hasIcon() {
            if (this.$route.path.startsWith(this.link.uri)) {
                if (this.link.icone === "far fa-circle") {
                    return "far fa-dot-circle";
                }
            }
            return this.link.icone;
        },
        isExterno() {
            return this.link.externo;
        },
    },
    methods: {
        preventRedirect(e, href) {
            e.preventDefault();
            this.verifyOpenClassInLink();
            this.verifyRedirect(href);
        },
        verifyOpenClassInLink() {
            if (this.linkHasOpenClass())
                this.$refs.link.classList.remove("open");
            else this.$refs.link.classList.add("open");
        },
        linkHasOpenClass() {
            return this.$refs.link.classList.contains("open");
        },
        verifyRedirect(href) {
            const routesToRedirect = [
                "/tempo-de-percurso",
                "/gestao-integrada",
                "/velocidade-media",
                "/origem-e-destino",
            ];

            if (routesToRedirect.includes(href))
                this.$router.push(`${href}/mapa`);
        },
        emitClick() {
            this.$emit("click");
        },
    },
};
</script>

<style></style>
