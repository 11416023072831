<template>
    <ViasGoogleMapsEquipamento
        v-for="equipamento in equipamentosMapa"
        :key="equipamento.id"
        :id="equipamento.id"
        :equipamento="equipamento"
        @click="clickEquipamento(equipamento)"
    />
</template>

<script lang="ts" setup>
import { converterEquipamentoParaMarcador } from "@/composable/equipamento/converterEquipamentoParaMarcador";
import { IEquipamentoMapa } from "@/interfaces/equipamento/IEquipamentoMapa";
import { useStore } from "@/storeTs";
import {
    BUSCAR_EQUIPAMENTOS,
    BUSCAR_EQUIPAMENTOS_ESTATICOS,
} from "@/storeTs/equipamentos/type-actions";
import {
    onMounted,
    ref,
    defineEmits,
    watch,
    computed,
    withDefaults,
    defineProps,
    defineModel,
} from "vue";
import ViasGoogleMapsEquipamento from "../ViasGoogleMapsEquipamento.vue";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { EquipamentoComPosicao } from "@/domains/Equipamentos/models/EquipamentoComPosicao";
import { EquipamentoComFaixaEPosicao } from "@/domains/Equipamentos/models/EquipamentoComFaixaEPosicao";



/* -------------------------------------------------------------------------- */
/*                        Seção de definições iniciais                        */
/* -------------------------------------------------------------------------- */

interface Props {
    equipamentosPorPropriedade?: IEquipamento[] | EquipamentoComPosicao[] | EquipamentoComFaixaEPosicao[];
    habilitarTodosOsEquipamentos?: boolean;
    idsEquipamentosSelecionados?: number[];
    filtroDeEquipamentos?: string;
}

const props = withDefaults(defineProps<Props>(), {
    equipamentosPorPropriedade: () => [],
    habilitarTodosOsEquipamentos: true,
    idsEquipamentosSelecionados: () => [],
    filtroDeEquipamentos: "",
});

const emits = defineEmits<{
    click: [equipamento: IEquipamento];
}>();

const equipamentosApresentados = defineModel<IEquipamento[]>("equipamentosNoMapa", {
    default: []
});
const equipamentosMapa = ref<IEquipamentoMapa[]>([]);

const store = useStore();

const accessToken = computed(() => store.state.access_token);


watch(() => props.filtroDeEquipamentos, () => {
    atualizarOsEquipamentosApresentadosNoMapa();
});


/* -------------------------------------------------------------------------- */
/*                        Seção dos equipamentos fixos                        */
/* -------------------------------------------------------------------------- */

const equipamentos = ref<IEquipamento[]>([]);

const tiposDeEquipamentosSelecionados = computed(
    () => store.state.tipos_equipamentos_selecionados,
);

async function buscarTodosOsEquipamentos() {
    if (!props.habilitarTodosOsEquipamentos) {
        return;
    }

    const response = await store.dispatch(BUSCAR_EQUIPAMENTOS);
    equipamentos.value = response.data;
    atualizarOsEquipamentosApresentadosNoMapa();
}

watch(
    () => tiposDeEquipamentosSelecionados.value,
    () => {
        if (accessToken.value) {
            buscarTodosOsEquipamentos();
        }
    },
);



/* -------------------------------------------------------------------------- */
/*                       Seção de equipamentos estáticos                      */
/* -------------------------------------------------------------------------- */

const equipamentosEstaticos = ref<IEquipamento[]>([]);

const dataDaBuscaDeEquipamentosEstaticos = computed(
    () => store.state.dataBuscaEquipamentosEstaticos,
);
const apresentarEquipamentosEstaticos = computed(
    () => store.state.apresentarEquipamentosEstaticos,
);

async function buscarEquipamentosEstaticos() {
    if (!props.habilitarTodosOsEquipamentos) {
        return;
    }
    
    const response = await store.dispatch(BUSCAR_EQUIPAMENTOS_ESTATICOS);
    equipamentosEstaticos.value = response.data;
    atualizarOsEquipamentosApresentadosNoMapa();
}

watch(
    () => dataDaBuscaDeEquipamentosEstaticos.value,
    () => {
        buscarEquipamentosEstaticos();
    },
);

watch(
    () => apresentarEquipamentosEstaticos.value,
    () => {
        if (apresentarEquipamentosEstaticos.value) {
            buscarEquipamentosEstaticos();
        } else {
            equipamentosEstaticos.value = [];
            atualizarOsEquipamentosApresentadosNoMapa();
        }
    },
);



/* -------------------------------------------------------------------------- */
/*        Seção dos equipamentos passados por propriedade ao componente       */
/* -------------------------------------------------------------------------- */

watch(
    () => props.equipamentosPorPropriedade,
    () => {
        atualizarOsEquipamentosApresentadosNoMapa();
    },
);



/* -------------------------------------------------------------------------- */
/*                  Seção de funções gerais dos equipamentos                  */
/* -------------------------------------------------------------------------- */

async function clickEquipamento(equipamento: IEquipamentoMapa) {
    try {
        const equipamentoBase = await buscarEquipamentoPeloId(equipamento.id);
        emits("click", equipamentoBase);
    } catch (error) {
        console.error(error);
    }
}

async function buscarEquipamentoPeloId(id: number): Promise<IEquipamento> {
    let equipamento = equipamentos.value.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }

    equipamento = equipamentosEstaticos.value.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }
    
    equipamento = props.equipamentosPorPropriedade.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }

    throw new Error(`Equipamento com o id ${id} não existe`);
}

function isEquipamentoSelecionado(equipamento: IEquipamentoMapa): boolean {
    return props.idsEquipamentosSelecionados.includes(equipamento.id);
}

function atualizarOsEquipamentosApresentadosNoMapa() {
    const todosOsEquipamentos = [
        ...equipamentos.value,
        ...equipamentosEstaticos.value,
        ...props.equipamentosPorPropriedade
    ];
    equipamentosApresentados.value = filtrarEquipamentos(todosOsEquipamentos);
    setTimeout(() => {
        equipamentosMapa.value = equipamentosApresentados.value.map((equipamento) => {
            let equipamentoConvertido = converterEquipamentoParaMarcador(equipamento);
            equipamentoConvertido.selecionado = isEquipamentoSelecionado(equipamentoConvertido);
            return equipamentoConvertido;
        });
    });
}

function filtrarEquipamentos(equipamentos: IEquipamento[]) {
    const equipamentosFiltrados: IEquipamento[] = [];
    const regex = new RegExp(props.filtroDeEquipamentos, "i");
    for (const equipamento of equipamentos) {
        if (regex.exec(equipamento.sitio) || regex.exec(equipamento.posicao.endereco)) {
            equipamentosFiltrados.push(equipamento);
        }
    }
    return equipamentosFiltrados;
}

watch(
    () => props.idsEquipamentosSelecionados,
    () => {
        atualizarOsEquipamentosApresentadosNoMapa();
    },
);



/* -------------------------------------------------------------------------- */
/*                   Seção de quando o componente é montado                   */
/* -------------------------------------------------------------------------- */

onMounted(() => {
    buscarTodosOsEquipamentos();
    buscarEquipamentosEstaticos();
    atualizarOsEquipamentosApresentadosNoMapa();
});
</script>
