<template>

<div class="d-flex flex-column nowrap overflow-y-auto h-100">
    <ViasRow>
        <ViasCol>
            <ViasInput
                label="Buscar por sítio ou endereço"
                placeholder="FV556SJC ou Av. Andrômeda"
                v-model="filtroDeEquipamentos"
                type="text"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol>
            <ul class="list clickable">
                <li v-for="equipamento in equipamentos" :key="equipamento.id" @click="zoomNoEquipamento(equipamento)">
                    <div>
                        <h3>{{ equipamento.sitio }}</h3>
                        <p>{{ equipamento.posicao.endereco }}</p>
                    </div>
                </li>
            </ul>
        </ViasCol>
    </ViasRow>
</div>

</template>

<script lang="ts" setup>
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { defineModel, defineProps, withDefaults, defineEmits } from "vue";
import ViasInput from "../ViasInput.vue";

interface Props {
    equipamentos: IEquipamento[];
}

withDefaults(defineProps<Props>(), {
    equipamentos: () => [] as IEquipamento[]
});

const emit = defineEmits<{
    zoomNoEquipamento: [e: IEquipamento]
}>();

const filtroDeEquipamentos = defineModel<string>("filtroDeEquipamentos");

function zoomNoEquipamento(equipamento: IEquipamento) {
    emit("zoomNoEquipamento", equipamento);
}
</script>