<template>
    <div>
        <div
            class="background-aside"
            :class="isMenuOpen"
            @click="closeMenu"
        ></div>
        <aside :class="[theme, isMenuOpen]">
            <div class="top">
                <div class="logo">
                    <img src="@/assets/logo-foto.png" alt="" />
                </div>
            </div>
            <div class="pt-4">
                <h3 class="text-center">Versão 2.8.0</h3>
            </div>
            <div class="sidebar">
                <vias-aside-link
                    v-for="link in links"
                    :key="link"
                    :link="link"
                    @click="sair"
                />
            </div>
        </aside>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ViasAsideLinkVue from "./ViasAsideLink.vue";
import swal from "sweetalert2";

export default {
    name: "vias-aside",
    components: {
        "vias-aside-link": ViasAsideLinkVue,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
            menu_open: (state) => state.menu_open,
        }),
        isMenuOpen() {
            return this.menu_open ? "open" : "";
        },
    },
    data: () => {
        return {
            links: [
                {
                    texto: "Contrato",
                    uri: "/contrato",
                    icone: "fas fa-scroll",
                    data_cy: "menu-contrato",
                },
                {
                    texto: "Gestão Integrada",
                    uri: "/gestao-integrada",
                    icone: "fas fa-recycle",
                    data_cy: "menu-gestao-integrada",
                    children: [
                        {
                            texto: "Mapa",
                            icone: "far fa-circle",
                            uri: "/gestao-integrada/mapa",
                            data_cy: "menu-gestao-integrada-mapa",
                        },
                        {
                            texto: "Grupos",
                            icone: "far fa-circle",
                            uri: "/gestao-integrada/grupos",
                            data_cy: "menu-gestao-integrada-grupos",
                        },
                    ],
                },
                {
                    texto: "Velocidade Média",
                    uri: "/velocidade-media",
                    icone: "fas fa-tachometer-alt",
                    data_cy: "menu-velocidade-media",
                    children: [
                        {
                            texto: "Mapa",
                            icone: "far fa-circle",
                            uri: "/velocidade-media/mapa",
                        },
                        {
                            texto: "Relatórios",
                            icone: "far fa-circle",
                            uri: "/velocidade-media/relatorios",
                            children: [
                                {
                                    texto: "Histórico de Infrações de Velocidade Média",
                                    icone: "far fa-circle",
                                    uri: "/velocidade-media/relatorios/historico-de-infracoes-de-velocidade-media",
                                },
                                {
                                    texto: "Infrações",
                                    icone: "far fa-circle",
                                    uri: "/velocidade-media/relatorios/infracoes",
                                },
                                {
                                    texto: "Velocidade Média",
                                    icone: "far fa-circle",
                                    uri: "/velocidade-media/relatorios/velocidade-media",
                                },
                            ],
                        },
                        {
                            texto: "Configuração",
                            icone: "far fa-circle",
                            uri: "/velocidade-media/configuracao",
                        },
                        {
                            texto: "Auditoria Velocidade Média",
                            icone: "far fa-circle",
                            uri: "/velocidade-media/auditoria",
                            super_usuario: true,
                        },
                    ],
                },
                {
                    texto: "Tempo de Percurso",
                    uri: "/tempo-de-percurso",
                    icone: "fas fa-road",
                    children: [
                        {
                            texto: "Mapa",
                            icone: "far fa-circle",
                            uri: "/tempo-de-percurso/mapa",
                        },
                        {
                            texto: "Relatórios",
                            icone: "far fa-circle",
                            uri: "/tempo-de-percurso/relatorios",
                            children: [
                                {
                                    texto: "Fluxo",
                                    icone: "far fa-circle",
                                    uri: "/tempo-de-percurso/relatorios/fluxo",
                                },
                                {
                                    texto: "Últimos Cálculos",
                                    icone: "far fa-circle",
                                    uri: "/tempo-de-percurso/relatorios/ultimos-calculos",
                                },
                            ],
                        },
                        {
                            texto: "Configuração",
                            icone: "far fa-circle",
                            uri: "/tempo-de-percurso/configuracao",
                        },
                        {
                            texto: "Auditoria Tempo de Percurso",
                            icone: "far fa-circle",
                            uri: "/tempo-de-percurso/auditoria",
                            super_usuario: true,
                        },
                    ],
                },
                {
                    texto: "Origem e Destino",
                    uri: "/origem-e-destino",
                    icone: "fas fa-route",
                    children: [
                        {
                            texto: "Mapa",
                            icone: "far fa-circle",
                            uri: "/origem-e-destino/mapa",
                        },
                        {
                            texto: "Configuração",
                            icone: "far fa-circle",
                            uri: "/origem-e-destino/configuracao",
                        },
                        {
                            texto: "Auditoria Origem e Destino",
                            icone: "far fa-circle",
                            uri: "/origem-e-destino/auditoria",
                            super_usuario: true,
                        },
                    ],
                },

                {
                    texto: "Relatórios",
                    icone: "fas fa-scroll",
                    uri: "/relatorios",
                    children: [
                        {
                            texto: "Excesso de Velocidade",
                            icone: "far fa-circle",
                            uri: "/relatorios/excesso-de-velocidade",
                        },
                        {
                            texto: "Excesso de Velocidade por Classe",
                            icone: "far fa-circle",
                            uri: "/relatorios/excesso-de-velocidade-por-classe",
                        },
                        {
                            texto: "Fluxo Veicular",
                            icone: "far fa-circle",
                            uri: "/relatorios/fluxo-veicular",
                        },
                        {
                            texto: "Impacto",
                            icone: "far fa-circle",
                            uri: "/relatorios/impacto",
                        },
                        {
                            texto: "Mapa de Calor de Excessos de Velocidade",
                            icone: "far fa-circle",
                            uri: "/relatorios/excessos-de-velocidade",
                        },
                        {
                            texto: "Mapa de Calor de Índice de Leitura de Placa",
                            icone: "far fa-circle",
                            uri: "/relatorios/indice-de-leitura-de-placa",
                        },
                        {
                            texto: "Mapa de Calor de Volume Horário de Tráfego",
                            icone: "far fa-circle",
                            uri: "/relatorios/volume-horario-de-trafego",
                        },
                        {
                            texto: "Infrações Gerais",
                            icone: "far fa-circle",
                            uri: "/relatorios/infracoes-gerais",
                        },
                        {
                            texto: "Origem e Destino",
                            icone: "far fa-circle",
                            uri: "/relatorios/origem-e-destino",
                        },
                        {
                            texto: "Total de Infrações por Enquadramento",
                            icone: "far fa-circle",
                            uri: "/relatorios/total-de-infracoes-por-enquadramento",
                        },
                        {
                            texto: "Total de Infrações por Equipamento",
                            icone: "far fa-circle",
                            uri: "/relatorios/total-de-infracoes-por-equipamento",
                        },
                        {
                            texto: "Volume Diário de Tráfego",
                            icone: "far fa-circle",
                            uri: "/relatorios/volume-diario-trafego",
                        },
                        {
                            texto: "Volume Médio Diário por Mês",
                            icone: "far fa-circle",
                            uri: "/relatorios/volume-medio-diario-por-mes",
                        },
                    ],
                },
                {
                    texto: "Configuração Geral",
                    uri: "/configuracao-geral",
                    icone: "fas fa-cog",
                    data_cy: "menu-configuracao-geral",
                    children: [
                        {
                            texto: "Agendamentos de relatórios",
                            icone: "far fa-circle",
                            uri: "/configuracao-geral/agendamentos"
                        },
                        {
                            texto: "Áreas",
                            icone: "far fa-circle",
                            uri: "/configuracao-geral/areas/mapa",
                            super_usuario: true,
                        },
                        {
                            texto: "Horários",
                            icone: "far fa-circle",
                            uri: "/configuracao-geral/horarios"
                        },
                        {
                            texto: "Whitelist",
                            icone: "far fa-circle",
                            uri: "/configuracao-geral/whitelist",
                            data_cy: "menu-configuracao-geral-whitelist",
                        },
                    ],
                },
                {
                    texto: "Configuração do Sistema",
                    uri: "/configuracao",
                    icone: "fas fa-wrench",
                    super_usuario: true,
                    data_cy: "menu-configuracao",
                    children: [
                        {
                            texto: "Usuários",
                            icone: "far fa-circle",
                            uri: "/configuracao/usuarios",
                            data_cy: "menu-configuracao-usuarios",
                        },
                        {
                            texto: "Auditoria Usuários",
                            icone: "far fa-circle",
                            uri: "/configuracao/auditoria-usuarios",
                            super_usuario: true,
                        },
                    ],
                },
                {
                    texto: "Suporte",
                    uri: "https://new.infraspeak.com/",
                    externo: true,
                    icone: "fas fa-headset"
                },
                {
                    texto: "Sair",
                    uri: "/sair",
                    icone: "fas fa-sign-out-alt",
                },
            ],
        };
    },
    methods: {
        closeMenu() {
            this.$store.dispatch("closeMenu");
        },
        sair() {
            swal.fire({
                title: "Aviso!",
                text: "Tem certeza que deseja sair do sistema?",
                icon: "warning",
                showConfirmButton: true,
                confirmButtonText: "Sim",
                showDenyButton: true,
                denyButtonText: "Não",
                didOpen(popup) {
                    popup
                        .getElementsByClassName("swal2-confirm")[0]
                        .setAttribute("data-cy", "popup-confirm");
                    popup
                        .getElementsByClassName("swal2-deny")[0]
                        .setAttribute("data-cy", "popup-deny");
                    popup
                        .getElementsByClassName("swal2-cancel")[0]
                        .setAttribute("data-cy", "popup-cancel");
                },
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$store.dispatch("removerToken");
                    this.$router.push("/login");
                }
            });
        },
    },
};
</script>
