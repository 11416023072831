<template>
    <div class="d-flex flex-column w-100">
        <h3 class="mb-2">Tipos</h3>
        <ul class="list">
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/FV/online.png"
                    alt="Ícone de equipamento de velocidade"
                />
                <h4>Velocidade</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/FS/online.png"
                    alt="Ícone de equipamento de semáforo"
                />
                <h4>Semáforo</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/FL/online.png"
                    alt="Ícone de equipamento de lombada"
                />
                <h4>Lombada</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/FM/online.png"
                    alt="Ícone de equipamento MCI"
                />
                <h4>MCI</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/ES/online.png"
                    alt="Ícone de equipamento estático"
                />
                <h4>Estático</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/DF/online.png"
                    alt="Ícone de equipamento não identificado"
                />
                <h4>Não identificado</h4>
            </li>
        </ul>
    </div>
</template>
