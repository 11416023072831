<template>
    <div class="mapa-aplicacao overflow-x-hidden border-radius-1">
        <div class="p-relative overflow-x-hidden w-100 h-100">
            <ViasGoogleMaps ref="mapa">
                <ViasEquipamentosMapa
                    :habilitar-todos-os-equipamentos="
                        habilitarTodosOsEquipamentos
                    "
                    :equipamentos-por-propriedade="equipamentosPorPropriedade"
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                    :filtro-de-equipamentos="filtroDeEquipamentos"
                    v-model:equipamentos-no-mapa="equipamentosNoMapa"
                    @click="clickEquipamento($event)"
                />
                <ViasAreasMapa v-if="habilitarTodasAsAreas" />
                <ViasRotasMapa :rotas="rotas" @click-rota="clickRota($event)" />
            </ViasGoogleMaps>
            <ViasMapaMenuLateral
                :habilitar-filtro-de-equipamentos="
                    habilitarFiltroDeEquipamentos
                "
                :habilitar-filtro-de-data-dos-equipamentos-estaticos="
                    habilitarFiltroDeDataDosEquipamentosEstaticos
                "
                :secoes-adicionais="secoesAdicionaisMenuLateral"
                v-model:filtro-de-equipamentos="filtroDeEquipamentos"
                :equipamentos="equipamentosNoMapa"
                @zoom-no-equipamento="zoomNoEquipamento($event)"
            >
                <template
                    v-for="secao in secoesAdicionaisMenuLateral"
                    #[secao.alvo]
                >
                    <slot :name="secao.alvo">
                        {{ secao.alvo }}
                    </slot>
                </template>
            </ViasMapaMenuLateral>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, withDefaults, defineModel, ref } from "vue";
import ViasAreasMapa from "../ViasAreasMapa.vue";
import ViasGoogleMaps from "../ViasGoogleMaps.vue";
import ViasEquipamentosMapa from "./ViasEquipamentosMapa.vue";
import ViasMapaMenuLateral from "./ViasMapaMenuLateral.vue";
import ViasRotasMapa from "./ViasRotasMapa.vue";
import { ISecaoMapaMenuLateral } from "@/interfaces/mapa/menu-lateral/ISecaoMapaMenuLateral";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { EquipamentoComPosicao } from "@/domains/Equipamentos/models/EquipamentoComPosicao";
import { EquipamentoComFaixaEPosicao } from "@/domains/Equipamentos/models/EquipamentoComFaixaEPosicao";

interface Props {
    habilitarTodosOsEquipamentos?: boolean;
    habilitarTodasAsAreas?: boolean;
    habilitarFiltroDeEquipamentos?: boolean;
    habilitarFiltroDeDataDosEquipamentosEstaticos?: boolean;
    equipamentosPorPropriedade?: IEquipamento[] | EquipamentoComPosicao[] | EquipamentoComFaixaEPosicao[];
    idsEquipamentosSelecionados?: number[];
    secoesAdicionaisMenuLateral?: ISecaoMapaMenuLateral[];
    rotas?: IRotaMapa[];
}

withDefaults(defineProps<Props>(), {
    habilitarTodosOsEquipamentos: true,
    habilitarTodasAsAreas: true,
    habilitarFiltroDeEquipamentos: true,
    habilitarFiltroDeDataDosEquipamentosEstaticos: true,
    equipamentosPorPropriedade: () => [],
    idsEquipamentosSelecionados: () => [],
    secoesAdicionaisMenuLateral: () => [],
    rotas: () => [],
});

const emits = defineEmits<{
    clickEquipamento: [equipamento: IEquipamento];
    clickRota: [rota: IRotaMapa];
}>();

const filtroDeEquipamentos = defineModel<string>("filtroDeEquipamentos");

const equipamentosNoMapa = ref<IEquipamento[]>([]);

const mapa = ref<any>();

async function clickEquipamento(equipamento: IEquipamento) {
    emits("clickEquipamento", equipamento);
}

async function clickRota(rota: IRotaMapa) {
    emits("clickRota", rota);
}

function zoomNoEquipamento(equipamento: IEquipamento) {
    mapa.value.map.setCenter({
        lat: equipamento.posicao.latitude,
        lng: equipamento.posicao.longitude
    });
    mapa.value.map.setZoom(15);
}
</script>
